<template>
  <div>
    <p class="collection">
      <el-button :type="article=='1'?'primary':''" @click="tapBtn('1')">我的文章</el-button>
      <el-button :type="article=='2'?'primary':''" @click="tapBtn('2')">我的快讯</el-button>
      <el-button :type="article=='3'?'primary':''" @click="tapBtn('3')">我的提问</el-button>
      <el-button :type="article=='4'?'primary':''" @click="tapBtn('4')">我的回答</el-button>
    </p>
    <ul class="xl">
      <li class="plcont" v-for="(item, index) in Collectionlist" :key="index">
        <ul>
          <li class="name" v-if="item.title.length<50">{{ item.title }}</li>
          <li
            class="name"
            v-if="item.title.length>=50&&item.title.length<100"
          >{{ item.title.substring(0,50)+"......" }}</li>
          <li
            class="name"
            v-if="item.title.length>=100&&item.title.length<500"
          >{{ item.title.substring(0,200)+"......" }}</li>
          <li
            class="name"
            v-if="item.title.length>=500&&item.title.length<1000"
          >{{ item.title.substring(0,350)+"......" }}</li>
          <li class="name" v-if="item.title.length>=1000">{{ item.title.substring(0,600)+"......" }}</li>
          <li class="names" v-if="item.questionTitle!=null">
            <!-- <span v-if="item.questionTitle.length>20">{{ item.questionTitle.substring(0,20)+"......" }}</span>  -->
            <span>{{ item.questionTitle}}</span>
          </li>
          <li class="user">
            <span class>{{ item.createTime }}</span>

            <span class>
              <el-button type="text" @click="deleteConcern(item.id)">删除</el-button>
            </span>

            <span class>
              <el-button type="text" @click="cancelConcern(item.id)">查看详情</el-button>
            </span>
          </li>
        </ul>
      </li>
    </ul>
    <div class="pageNum" v-show="Collectionlist.length<=0">
      暂无数据
    </div>
    <div class="pageNum">
        <el-pagination
        background
        :page-size="limit"
        @current-change="changePage"
        layout="prev, pager, next"
        :total="count">
        </el-pagination>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import { INSERT, QUERY, DELETE, QUERYED, UPDATE } from "@/services/dao.js";
import { AcceptNum, AcceptNumTeam } from "@/js/common/index.js";
import { formatDate, showTimeFormat } from "@/js/common";
export default {
  data() {
    return {
      Collectionlist: [],
      content: "",
      createTime: "",
      source: "",
      article: "1",
      // btnfla: "",
      // question: "",
      // answer: "",
      user: "2",

      limit:10,  //每页数据
      page:1,  //当前页
      count:0   //数据总数
    };
  },
  created() {
    this.changePage(1);
  },
  methods: {
    tapBtn(num){
      this.article = num
      this.changePage(1)
    },

    changePage(e){
      this.page = e
      this.Collectionlist = [];
      switch (this.article) {
        case '1':this.init()
          break;
        case '2':this.flash()
          break;
        case '3':this.MY()
          break;
        case '4':this.AS()
          break;
      }
    },

    ///我的文章
    async init() {
      let {$store,limit,page} = this
      let sql =`Article( where: {authorName: {_like: "%${$store.state.userinfo.name}%"}},limit:${limit},offset:${limit*(page-1)}) {id title createTime}`
      sql += ` Article_aggregate(where: {authorName: {_like: "%${$store.state.userinfo.name}%"}}) {aggregate {count}}`
      let res = await QUERYED(
        "post","",sql
      );
      this.count = res.data.Article_aggregate.aggregate.count
      this.Collectionlist = res.data.Article;
      for (let i = 0; i < this.Collectionlist.length; i++) {
        this.Collectionlist[i].createTime = showTimeFormat(
          this.Collectionlist[i].createTime
        );
      }
    },

    // 我的快讯
    async flash() {
      let {$store,limit,page} = this
      let sql =`News(where:{authorName:{_like:"%${$store.state.userinfo.name}%"}},limit:${limit},offset:${limit*(page-1)}) {id title createTime}`
      sql +=` News_aggregate(where:{authorName:{_like:"%${$store.state.userinfo.name}%"}}) {aggregate {count}}`

      let res = await QUERYED(
        "post","",sql
      );
      this.count = res.data.News_aggregate.aggregate.count
      this.Collectionlist = res.data.News;
      for (let i = 0; i < this.Collectionlist.length; i++) {
        this.Collectionlist[i].createTime = showTimeFormat(
          this.Collectionlist[i].createTime
        );
      }
    },

    // 我的提问
    async MY() {
      let {$store,limit,page} = this
      let sql =`WDQuestion(where:{questioner:{_eq:"${$store.state.userinfo.id}"}},limit:${limit},offset:${limit*(page-1)}) {id questionTitle createTime}`
      sql +=` WDQuestion_aggregate(where:{questioner:{_eq:"${$store.state.userinfo.id}"}}) {aggregate {count}}`
      this.Collectionlist = [];
      let res = await QUERYED(
        "post","",sql
      );
      this.count = res.data.WDQuestion_aggregate.aggregate.count
      for (let i = 0; i < res.data.WDQuestion.length; i++) {
        this.Collectionlist.push({
          title: res.data.WDQuestion[i].questionTitle,
          id: res.data.WDQuestion[i].id,
          createTime: showTimeFormat(res.data.WDQuestion[i].createTime)
        });
      }
    },

    // 我的回答
    async AS() {
      let {$store,limit,page} = this
      let sql =`WDUserQuestionAnswerRef(where:{user:{_eq:"${$store.state.userinfo.id}"}},limit:${limit},offset:${limit*(page-1)}) {id wdanswerref questionTitle answer createtimes}`
      sql +=` WDUserQuestionAnswerRef_aggregate(where:{user:{_eq:"${$store.state.userinfo.id}"}}) {aggregate {count}}`
      let res = await QUERYED(
        "post","",sql
      );
      this.count = res.data.WDUserQuestionAnswerRef_aggregate.aggregate.count
      for (let i = 0; i < res.data.WDUserQuestionAnswerRef.length; i++) {
        this.Collectionlist.push({
          title: "回答：" + res.data.WDUserQuestionAnswerRef[i].answer,
          id: res.data.WDUserQuestionAnswerRef[i].wdanswerref,
          createTime: showTimeFormat(
            res.data.WDUserQuestionAnswerRef[i].createtimes
          ),
          questionTitle:
            "问题：" + res.data.WDUserQuestionAnswerRef[i].questionTitle
        });
      }
    },

    async deleteConcern(id) {
      if (this.article == "1") {
        let obj = await DELETE(
          "POST",
          "",
          "delete_TtArticle(where: {id: {_eq: " +
            id +
            "}}) {    affected_rows  }"
        );
        if (obj.data.delete_TtArticle.affected_rows > 0) {
          this.init();
          this.$message({
            message: "删除成功",
            type: "success"
          });
        }
      } else if (this.article == "2") {
        let obj = await DELETE(
          "POST",
          "",
          " delete_TtArticle(where: {id: {_eq: " +
            id +
            "}}) {    affected_rows  }"
        );
        if (obj.data.delete_TtArticle.affected_rows > 0) {
          this.flash();
          this.$message({
            message: "删除成功",
            type: "success"
          });
        }
      } else if (this.article == "3") {
        let yinyong = await QUERYED(
          "post",
          "",
          " WDAnswerRef_aggregate(where: {id: {_eq:" +
            id +
            "},quetoType : {_eq:1}}){aggregate { count }}"
        );
        if (yinyong.data.WDAnswerRef_aggregate.aggregate.count == 1) {
          //被引用了
          this.$message({
            type: "warning",
            message: "您的问题已被引用，不能被删除哦！"
          });
        } else {
          //没有被引用
          ////删除问题
          let obj = await DELETE(
            "POST",
            "",
            " delete_WdQuestion(where: {id: {_eq: " +
              id +
              "}}) {    affected_rows  }"
          );

          if (obj.data.delete_WdQuestion.affected_rows > 0) {
            //删除问题成功！
            let datass = await QUERYED(
              "post",
              "",
              "WDAnswerRef( where: {question : {_eq:" + id + "}}) { id   }"
            );
            if (datass.data.WDAnswerRef.length > 0) {
              //如果有答案
              // 删除问题的回答
              let detels = await DELETE(
                "post",
                "",
                "  delete_WdAnswerRef(where: {question : {_eq: " +
                  id +
                  "}}) {    affected_rows  }"
              );
              if (detels.data.delete_WdAnswerRef.affected_rows > 0) {
                ///如果回答删除成功
                this.MY();
                this.$message({
                  message: "删除成功",
                  type: "success"
                });
              }
            } else {
              this.MY();
              this.$message({
                message: "删除成功",
                type: "success"
              });
            }
          }
        }
      } else if (this.article == "4") {
        ///查询该回答有无被采纳
        let res = await QUERYED(
          "post",
          "",
          " WDAnswerRef(where: {id: {_eq:" + id + "}}) {  adopt   question }"
        );
        if (res.data.WDAnswerRef[0].adopt == 0) {
          ///如果不是被采纳的答案
          let obj = await DELETE(
            "POST",
            "",
            " delete_WdAnswerRef(where: {id: {_eq: " +
              id +
              "}}) {    affected_rows  }"
          );

          if (obj.data.delete_WdAnswerRef.affected_rows > 0) {
            //获取问题表里面回答数数量
            let datdse = await QUERYED(
              "post",
              "",
              "  WDQuestion(where: {id: {_eq: " +
                res.data.WDAnswerRef[0].question +
                "}}) {  answerNum  }"
            );
            if (datdse.data.WDQuestion[0].answerNum == 0) {
              datdse.data.WDQuestion[0].answerNum = 0;
            } else {
              datdse.data.WDQuestion[0].answerNum -= 1;
            }
            ///修改该问题的回答数
            //修改回答数量
            let datdeds = await UPDATE(
              "post",
              "",
              "   update_WdQuestion(where: {id: {_eq: " +
                res.data.WDAnswerRef[0].question +
                "}}, _set: {answerNum: " +
                datdse.data.WDQuestion[0].answerNum +
                "}) { affected_rows  }"
            );
            if (datdeds.data.update_WdQuestion.affected_rows > 0) {
              ///如果修改成功
              //    ////查询评论表是否有数据（id为回答ID）
              let info1 = await QUERYED(
                "post",
                "",
                "  Comment(where: {whole: {_eq: " + id + "}}) {  id  }"
              );
              if (info1.data.Comment.length > 0) {
                ///删除评论表
                let totaled = await DELETE(
                  "post",
                  "",
                  "   delete_Comment(where: {whole: {_eq: " +
                    id +
                    "}}) {     affected_rows }"
                );
                if (totaled.data.delete_Comment.affected_rows > 0) {
                  this.AS();
                  this.$message({
                    message: "删除成功",
                    type: "success"
                  });
                }
              } else {
                //如果没有评论
                this.AS();
                this.$message({
                  message: "删除成功",
                  type: "success"
                });
              }
            }
          }
        } else {
          ///如果是被采纳的答案
          this.$message({
            type: "warning",
            message: "您的答案已被采纳，不能被删除哦！"
          });
        }
      }
      let user = this.$store.state.userinfo.id;
      AcceptNum(user); //刷新用户
      AcceptNumTeam(user); //刷新回答
    },
    async cancelConcern(id) {
      if (this.article == "1") {
        this.$router.push({ path: "articleDetail", query: { id: id } });
      } else if (this.article == "2") {
        this.$router.push({ path: "newsFlashDetail", query: { id: id } });
      } else if (this.article == "3") {
        this.$router.push({ path: "writeanswer", query: { id: id } });
      } else if (this.article == "4") {
        let res = await QUERY(
          "post",
          "",
          "  WDAnswerRef(where: {id: {_eq: " + id + "}}) {  question } "
        );
        this.$router.push({
          path: "writeanswer",
          query: { id: res.data.WDAnswerRef[0].question }
        });
      }
    }
  }
};
</script>

<style scoped>
.collection {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  padding: 5px 21px 21px 21px;
  border-bottom: solid 1px #e8e8e8;
}
ul {
  padding: 0px;
}
.el-button {
  margin-left: 60px;
}
.active {
  background: #0084ff;
  color: #ffffff;
}
.plcont {
  padding: 20px 20px 19px 30px;
  border-bottom: solid 1px #e8e8e8;
}
.name {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 14px;
  /* line-height: 15px; */
  /* border: 1px solid red; */
  word-wrap: break-word;
  word-break: normal;
  width: 90%;
}
.names {
  word-wrap: break-word;
  word-break: normal;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 14px;
  /* line-height: 20px; */
  /* border: 1px solid red; */
  width: 90%;
}
ul > li > ul > li {
  line-height: 20px;
  /* border: 1px solid red; */
}
.user span {
  /* border: 1px solid forestgreen; */
  display: inline-block;
  /* margin-right: 30px; */
}
.num {
  float: right;
}
.pageNum{
  width: 100%;
  padding-top: 20px;
  display: flex;
  justify-content: center;
}
</style>
